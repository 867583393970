import { RouteComponentProps, useParams } from '@reach/router';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { PageHeader, PageTemplate } from 'DesignSystem/Layout/Pages';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import {
  DashboardFilterMapping,
  fetchTableauToken,
} from 'services/api-insights';
import TableauEmbed from '../../../../../components/tableauEmbed';
import { Box } from '../../../../../DesignSystem/Components';
import { Flex } from '../../../../../DesignSystem/Layout/Flex';
import { useDataJobMetrics } from '../../../../../hooks/data-jobs';
import { useWorkbookQuery } from '../../../../../hooks/insights/useInsightsPlusApi';
import { displayLargestTimeInterval } from '../../../../../utility/datetimes';
import { DashboardFilterBar } from '../components/DashboardFilterBar';
import { DashboardFilterLanding } from '../components/DashboardFilterLanding';
import { ShimmerLoading } from '../components/ShimmerLoading';
import { DashboardFilterProvider } from '../contexts/DashboardFilterContext';
import styles from './tableau.module.css';
import { DashboardWidgets } from '../components/Widgets/DashboardWidgets/DashboardWidgets';
import {
  getFeatureMetaData,
  useTableauDashboardFeatures,
} from '../../../../../hooks/tableau-dashboard';
import { LightBulb3 } from '../../../../../shared/icons';

export type TableauPropsType = {
  workbookId?: string;
  id?: string;
  path: string;
} & RouteComponentProps;

// Preload font which is embedded into the tableau reports to avoid FOUC (flash of unloaded content)
const customFontDomain = `${process.env.REACT_APP_TABLEAU_DOMAIN}/custom-fonts/webfont.html`;

type DashboardFeatureMetaData = {
  helpLink: string;
};

const FEATURE_MAPPING: {
  [key: string]: DashboardFeatureMetaData | undefined;
} = {
  'executive.overview': {
    helpLink: '',
  },
  'executive.initiatives': {
    helpLink: '',
  },
  'executive.community': {
    helpLink: '',
  },
};

export const Tableau: React.FC<TableauPropsType> = () => {
  const { dashboard_id: dashboardId, workbook_id: workbookId } = useParams();
  const { results: dataFreshnessResults } = useDataJobMetrics(workbookId);
  const programId = useProgram().id;
  const vizRef = useRef<Tableau.ITableauViz>(null);

  const [initialized, setInitialized] = useState(false);
  const [contentUrl, setContentUrl] = useState<string>();

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [tableauVizLoading, setTableauVizLoading] = useState(true);

  const [dashboardName, setDashboardName] = useState<string>();

  const [showLanding, setShowLanding] = useState(true);

  const { isLoading: workbookLoading, workbook } = useWorkbookQuery(
    programId,
    workbookId
  );

  const {
    isLoading: featuresLoading,
    data: features,
  } = useTableauDashboardFeatures({ dashboardId });

  const selectedDashboard = useMemo(
    () => workbook?.views.view.find((d) => d.id === dashboardId),
    [dashboardId, workbook?.views.view]
  );

  // Load required token and dashboard for the tableau viz.
  const { isLoading: tokenLoading, isError, data: token } = useQuery(
    ['load-tableauEmbed', programId, workbookId],
    async (): Promise<string> => {
      return fetchTableauToken(programId);
    },
    { cacheTime: 0 }
  );

  const [helpLink, setHelpLink] = useState<string>();

  const tabs =
    workbook?.views.view.map((db) => ({
      to: `${db.id}`,
      label: db.name,
    })) ?? undefined;

  const handleTabSwitched = (_name?: Tableau.CustomTableauEvent<unknown>) => {
    const tempActiveSheet = vizRef.current?.workbook.activeSheet;
    if (tempActiveSheet?.name !== selectedDashboard?.name) {
      activateSheet(selectedDashboard?.name ?? '');
    } else {
      setTableauVizLoading(false);
    }
  };

  const activateSheet = React.useCallback((name: string) => {
    setTableauVizLoading(true);
    vizRef.current?.workbook.activateSheetAsync(name).then((sheet) => {
      if (sheet?.size.maxSize) {
        const styleString = `height: ${sheet.size.maxSize.height}px; width: ${sheet.size.maxSize.width}px; border: none;`;
        // Required to resize iframe when switching between tabs
        // (tableau adds inline styles that have to be overridden)
        vizRef.current?.iframe?.setAttribute('style', styleString);
      }
      document.querySelector('main')?.scrollTo(0, 0);
    });
  }, []);

  // To avoid rerender of entire Viz component, only set the contentUrl load once the workbook is loaded. Tableau
  // does not require it to be updated when switching between dashboards.
  useEffect(() => {
    if (workbook && !initialized) {
      setContentUrl(
        workbook?.views.view.find((d) => d.id === dashboardId)?.contentUrl
      );
      setInitialized(true);
    }
  }, [workbook, setContentUrl, initialized, setInitialized, dashboardId]);

  useEffect(() => {
    if (!selectedDashboard || featuresLoading || features === undefined) return;
    if (dashboardName !== selectedDashboard.name) {
      setDashboardName(selectedDashboard.name);
      if (!tableauVizLoading && vizRef.current?.workbook !== undefined) {
        // switch viz to the currently selected dashboard
        activateSheet(selectedDashboard.name);
      }
    }
    features?.forEach(({ key, metaData: metaDataJson }) => {
      if (FEATURE_MAPPING[key] !== undefined) {
        const metaData = getFeatureMetaData<DashboardFeatureMetaData>(
          metaDataJson
        );
        setHelpLink(metaData.helpLink);
      }
    });
  }, [
    workbook,
    tableauVizLoading,
    dashboardId,
    activateSheet,
    selectedDashboard,
    dashboardName,
    featuresLoading,
    features,
  ]);

  const onFilterChange = async (filter: string, values: string[]) => {
    try {
      let updateType = 'replace';
      if (!values.length) {
        updateType = 'all';
      }
      await vizRef.current?.workbook.activeSheet.applyFilterAsync(
        filter,
        values,
        updateType
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to change filters', e);
    }
  };

  const onParameterChanged = async (
    parameter: string,
    value: string | Date | number | boolean
  ) => {
    try {
      await vizRef.current?.workbook.changeParameterValueAsync(
        parameter,
        value
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to change parameters', e);
    }
  };

  const onApply = (appliedFilters: Record<string, DashboardFilterMapping>) => {
    const promises: Promise<void>[] = [];
    Object.values(appliedFilters).forEach((filter) => {
      if (!filter.value) return;
      if (filter.filter_type === 'filter') {
        promises.push(onFilterChange(filter.filter_key, filter.value));
      } else if (filter.filter_type === 'parameter') {
        promises.push(onParameterChanged(filter.filter_key, filter.value[0]));
      }
    });
    setShowLanding(false);
    if (promises.length === 0) {
      return;
    }
    setFiltersLoading(true);
    Promise.all(promises).then(() => {
      setFiltersLoading(false);
    });
  };

  const dashboardRefreshTimeInterval = useMemo(() => {
    if (!dataFreshnessResults?.dataFreshnessDate) {
      return null;
    }
    const duration = Interval.fromDateTimes(
      dataFreshnessResults?.dataFreshnessDate,
      DateTime.now().toUTC()
    ).toDuration(['days', 'hours', 'minutes', 'seconds']);

    return displayLargestTimeInterval(duration);
  }, [dataFreshnessResults]);

  const anyLoading = workbookLoading || tokenLoading;
  const dashboardLoading = !contentUrl || tokenLoading || tableauVizLoading;
  let actionsOverride;
  if (helpLink) {
    actionsOverride = (
      <a href={helpLink} target="_blank" rel="noopener noreferrer">
        <LightBulb3 />
      </a>
    );
  }
  return (
    <PageTemplate title="Dashboard">
      {isError && !anyLoading && (
        <p>Unable to load Dashboard. Please contact system admin.</p>
      )}
      {!isError && (
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          <Helmet>
            <script type="text/javascript" src={`${customFontDomain}`} />
          </Helmet>
          <PageHeader
            title={workbook?.name || 'Workbook'}
            breadcrumbs={[
              {
                label: 'Insights+',
                to: `/${programId}/app/insights/collections/overview`,
              },
              { label: workbook?.name || 'Workbook' },
            ]}
            tabs={tabs}
            actionsOverride={actionsOverride}
            filterbar={
              <Box>
                {dashboardRefreshTimeInterval && (
                  <Flex end>
                    <span>Refreshed: {dashboardRefreshTimeInterval}</span>
                  </Flex>
                )}
                {dashboardName && (
                  <DashboardFilterProvider
                    dashboardId={dashboardId}
                    updateFilterValue={() => {}}
                    appliedFilters={{}}
                    clearAppliedFilters={() => {}}
                    filters={{}}
                    dashboardFilters={{}}
                  >
                    {workbook && (
                      <DashboardFilterLanding
                        workbookName={workbook?.name}
                        dashboardId={dashboardId}
                        showLanding={showLanding}
                      >
                        <DashboardFilterBar onApply={onApply} />
                      </DashboardFilterLanding>
                    )}
                  </DashboardFilterProvider>
                )}
              </Box>
            }
          />
          {(dashboardLoading || filtersLoading) &&
            dashboardName &&
            !showLanding && <ShimmerLoading dashboardName={dashboardName} />}

          <div
            className={cx(styles.tableauVizContainer, {
              [styles.hidden]:
                dashboardLoading || filtersLoading || showLanding,
            })}
          >
            {contentUrl && !tokenLoading && (
              <TableauEmbed
                ref={vizRef}
                token={`${token}`}
                src={`${contentUrl}`}
                toolbar="hidden"
                hide-tabs
                iframe-auth
                onFirstInteractive={() => {
                  setTableauVizLoading(false);
                }}
                onTabSwitched={handleTabSwitched}
                initialQueryParams={{}}
              />
            )}
          </div>
          <DashboardWidgets dashboardId={dashboardId} />
        </div>
      )}
    </PageTemplate>
  );
};
