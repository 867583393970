import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useJourneyQuery } from '../../../../../../hooks/journeys/journeys';
import { useProgram } from '../../../../../../contexts/program';
import { LoadingChart } from '../../../../../../shared/Charts/LoadingChart';
import { NoDataChart } from '../../../../../../shared/Charts/NoDataChart';
import { useJourneyStepsInsightsMetricsQuery } from '../../../../../../hooks/journeyInsightsMetrics';
import { Step } from '../../../../../../models/journeys/journey';
import { JourneyStepsInsightsMetricsData } from '../../../../../../services/api-journey-metrics';
import { MAIcon } from '../../../../../../shared/MAIcon';

import style from './tableau-table-widget.module.css';
import { DashboardFilterContext } from '../../contexts/DashboardFilterContext';

type StepData = {
  stepId: string;
  stepName?: string;
  stepType?: string;
  entered?: number;
  current?: number;
  exited?: number;
  sent?: number;
  delivered?: number;
  error?: number;
};

const columnHelper = createColumnHelper<JourneyRow>();
const emptyArray: never[] = [];

type JourneyRow = StepData & {
  stepName?: string;
  stepType?: string;
  stepId: string;
  subRows: JourneyRow[] | undefined;
};

const buildJourneyRows = (
  journeySteps: Step[],
  metrics: JourneyStepsInsightsMetricsData
) => {
  const journeyMap: Record<string, Step> = {};
  journeySteps.forEach((step: Step) => {
    journeyMap[step.id] = step;
  });

  let currentStep = journeySteps.find((step) => step.type === 'start');
  const result: JourneyRow[] = [];

  while (currentStep) {
    result.push(buildJourneyRow(currentStep, journeyMap, metrics));
    currentStep = journeyMap[currentStep.next?.[0]?.targetId];
  }
  return result;
};

const centeredCell = (value: string | number | undefined) => (
  <span className={style.centeredCell}>{value}</span>
);

const buildJourneyRow = (
  step: Step,
  journeyMap: Record<string, Step>,
  metrics: JourneyStepsInsightsMetricsData
) => {
  let { name } = step;
  if (name === undefined || name.length === 0) {
    switch (step.type) {
      case 'delay':
        name = `Delay: ${step.quantity}${step.unit}`;
        break;
      case 'decision':
        name = `Decision: ${name}`;
        break;
      case 'communication':
      case 'end':
      case 'start':
      default:
    }
  }
  const result: JourneyRow = {
    stepId: step.id,
    stepName: name,
    stepType: step.type,
    ...metrics[step.id],
    subRows:
      step.type === 'decision'
        ? step.next.map(({ targetId }) => {
            return buildJourneyRow(journeyMap[targetId], journeyMap, metrics);
          })
        : undefined,
  };
  // eslint-disable-next-line no-param-reassign
  delete journeyMap[step.id];
  return result;
};

interface ITableauTableWidget {
  journeyId: string;
}

export const JourneyStepOverviewInsightsTable: React.FC = () => {
  const { appliedFilters } = React.useContext(DashboardFilterContext);
  const journeyId = useMemo(() => {
    return appliedFilters.journey_id?.value?.[0];
  }, [appliedFilters]);

  if (!journeyId) return null;
  return <JourneyStepOverviewInsightsTableInner journeyId={journeyId} />;
};

export const JourneyStepOverviewInsightsTableInner: React.FC<ITableauTableWidget> = ({
  journeyId,
}) => {
  const { id: programId } = useProgram();
  const { appliedFilters } = React.useContext(DashboardFilterContext);

  const query = useMemo(() => {
    const result: { [key: string]: unknown } = {};
    Object.keys(appliedFilters).forEach((k) => {
      result[k] = appliedFilters[k].value;
    });
    return result;
  }, [appliedFilters]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('stepName', {
        header: () => <span>Step</span>,
        cell: ({ cell, row }) => {
          const v = cell.getValue();
          return (
            <span
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              {row.getCanExpand() ? (
                <button
                  type="button"
                  className={style.expandButton}
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <MAIcon name="expand_more" />
                  ) : (
                    <MAIcon name="chevron_right" />
                  )}
                </button>
              ) : null}{' '}
              {v === undefined || v.length === 0 ? row.original.stepType : v}
            </span>
          );
        },
      }),
      columnHelper.accessor('entered', {
        header: () => <span>Entered</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('current', {
        header: () => <span>Current</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('exited', {
        header: () => <span>Exited</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('sent', {
        header: () => <span>Sent</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('delivered', {
        header: () => <span>Delivered</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('error', {
        header: () => <span>Error</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
    ],
    []
  );

  const { data: journey, isLoading } = useJourneyQuery({
    programId,
    journeyId: Number(journeyId),
  });

  const {
    data: journeyStepInsightsMetrics,
    isLoading: isLoadingStepInsightMetrics,
  } = useJourneyStepsInsightsMetricsQuery({ journeyId, query });

  const data = useMemo(() => {
    if (journeyStepInsightsMetrics === undefined) {
      return [];
    }
    const journeySteps =
      journey?.liveGraph?.steps || journey?.draftGraph?.steps || [];

    return buildJourneyRows(journeySteps, journeyStepInsightsMetrics);
  }, [
    journeyStepInsightsMetrics,
    journey?.liveGraph?.steps,
    journey?.draftGraph?.steps,
  ]);

  const table = useReactTable({
    columns,
    data: data ?? emptyArray,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (isLoading || isLoadingStepInsightMetrics) {
    return <LoadingChart />;
  }

  if (
    journeyId === undefined ||
    (journey?.liveGraph?.steps || journey?.draftGraph?.steps) === undefined
  ) {
    return <NoDataChart />;
  }

  return (
    <div className={style.tableauTableWidgetContainer}>
      <table className={style.tableauTableWidget}>
        <thead>
          {table.getHeaderGroups().map((hg) => {
            return (
              <tr key={hg.id}>
                {hg.headers.map((h) => (
                  <th key={h.id}>
                    {flexRender(h.column.columnDef.header, h.getContext())}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
