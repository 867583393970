import { Button } from '@socialchorus/shared-ui-components';
import React, { useCallback, useState } from 'react';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { FormSubsection, Section } from 'DesignSystem/Form';
import { Body, color } from 'DesignSystem/Typography';
import { useLinkNavigation } from 'hooks/use-link-navigation';
import {
  MergeIntegration,
  SupportedIntegration,
} from 'models/merge-integration';
import { clearUserMappings } from 'services/api-merge-integration';
import { getIntegrationLabel } from '../label';
import styles from './styles.module.css';

type MergeAdministrativeActionsProps = {
  type: SupportedIntegration;
  integration: MergeIntegration;
  userMappingPreviewPath?: string;
};

export const MergeAdministrativeActions: React.FC<MergeAdministrativeActionsProps> = ({
  type,
  integration,
  userMappingPreviewPath,
}) => {
  const navigate = useLinkNavigation();
  const { id: programId } = useProgram();

  const [isClearingUserMappings, setIsClearingUserMappings] = useState(false);
  const [shouldClearUserMappings, setShouldClearUserMappings] = useState(false);

  const onClearUserMappings = useCallback(async () => {
    setIsClearingUserMappings(true);
    try {
      await clearUserMappings(programId, integration.id);
    } finally {
      setIsClearingUserMappings(false);
    }
  }, [integration.id, programId]);

  const actionInProgress = isClearingUserMappings;
  const integrationLabel = getIntegrationLabel(type);

  return (
    <>
      <Section title="Administrative Actions">
        {userMappingPreviewPath && (
          <FormSubsection title="View User Mappings" divider={false}>
            <div className={styles.AdministrativeAction}>
              <Body color={color.gray60}>
                View the existing user mappings between {integrationLabel} and
                Firstup.
              </Body>
              <Button
                variant="primary"
                label="View User Mappings"
                href={navigate(userMappingPreviewPath)}
              />
            </div>
          </FormSubsection>
        )}

        <hr />

        <FormSubsection title="Clear User Mappings" divider={false}>
          <div className={styles.AdministrativeAction}>
            <Body color={color.gray60}>
              Existing user mappings may be obsolete if you have changed the
              user mapping configuration.
              <br />
              Use this to clear all the existing user mappings. The user
              mappings will be recreated based on the current configuration.
            </Body>
            <Button
              variant="danger"
              label="Clear User Mappings"
              isLoading={isClearingUserMappings}
              disabled={actionInProgress}
              onClick={() => setShouldClearUserMappings(true)}
            />
          </div>
        </FormSubsection>
      </Section>

      {shouldClearUserMappings && (
        <ConfirmModal
          title="Clear User Mappings?"
          confirmLabel="Clear mappings"
          onCancel={() => setShouldClearUserMappings(false)}
          onConfirm={onClearUserMappings}
        >
          <p>
            You are about to clear all user mappings between {integrationLabel}{' '}
            and Firstup. This action cannot be undone.
          </p>
          <br />
          <p>
            Normally, this action is only needed when you have changed the user
            mapping configuration. Are you sure you want to continue with
            clearing the mappings?
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
